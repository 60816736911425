import React, { useState } from 'react'
import { FormControl, FormErrorMessage, Select } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import CustomForm from './customForm'


const ReviewConvo = () => {

    const [formData, setFormData] = useState({})
    const [selectData, setSelectData] = useState({ form: '', data: '' })
    const [errors, setErrors] = useState({})

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors({...errors, [name] : ''})
    };

    const handleSelect = (e) => {
        const { name, value } = e.target
        setSelectData(prevData => ({
            ...prevData,
            [name]: value
        }))
        setErrors({...errors, [name] : ''})
    }

    const validateForm = (data) => {
        let newErrors = {}
    
        formFields.forEach((field) => {
          if(!data[field.name]) {
            newErrors[field.name] = `${field.label} is required`
          }
        })
        if(!selectData.form) {
          newErrors.form = 'Form Type is Required'
        }
    
        return newErrors
      }

    const handleSubmit = (e) => {
        e.preventDefault()
        const fullForm = { ...formData, ...selectData }
        const newErrors = validateForm(fullForm)
        setErrors(newErrors)
        console.log('Form Submitted:', fullForm)
    }


    const formFields = [
        { name: 'paste', label: 'Paste The Question Here', type: 'text' },
        { name: 'colum', label: 'Column Label', placeholder: 'E.g Date of Birt or Question 1', type: 'text' },
        { name: 'default', label: 'Default Value if Skipped', type: 'text' }
    ]

    return (

        <div className="bg-white rounded-lg shadow-md p-6 mt-4">

            <div className='ml-3'>

                <p className='text-2xl font-semibold mb-5'>Add Conversation To A Form</p>

            <form onSubmit={handleSubmit}>

                <FormControl isInvalid={errors.form}>
                <Select
                    name='form'
                    value={selectData.form}
                    onChange={handleSelect}
                    iconColor='transparent'
                    placeholder='Select The Form'
                    className='p-2 w-3/12 border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'>
                    <option value='Form Sample 1'>MPESA Functionality Testing</option>
                    <option value='Form Sample 2'>Junior Front-end Engineer</option>
                </Select>
                <FormErrorMessage className="text-red-600 text-lg mb-8">{errors.form}</FormErrorMessage>
                </FormControl>

               
                <CustomForm formFields={formFields} onChange={handleChange} onSubmit={handleSubmit} formData={formData} errors={errors} />

                <span>
                    Optional:
                    <Select
                        name='data'
                        value={selectData.data}
                        onChange={handleSelect}
                        iconColor='transparent'
                        placeholder='Specify Data Type'
                        className='p-2 w-3/12 border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'>
                        <option value='Free from Text'>Free from Text</option>
                        <option value='Email Address'>Email Address</option>
                        <option value='Account Number'>Account Number</option>
                        <option value='Location'>Location</option>
                        <option value='Contact Mobile Number'>Contact Mobile Number</option>
                        <option value='MPESA Mobile Number'>MPESA Mobile Number</option>
                        <option value='Currency Short Code'>Currency Short Code </option>
                        <option value='Amount'>Amount</option>
                        <option value='Number'>Number</option>
                        <option value='Date'>Date</option>
                        <option value='Time'>Time</option>
                        <option value='One Character'>One Character</option>
                        <option value='Weight In Kg'>Weight In Kg</option>
                    </Select>
                </span>

                <Button
                    type='submit'
                    className='bg-indigo-200 py-2 px-6 text-lg rounded-lg hover:bg-indigo-500'>
                    Add Question
                </Button>

            </form>

            </div>

        </div>

    )
}


export default ReviewConvo