import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import InputField from 'components/fields/InputField';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import Footer from 'components/footer/FooterAuthDefault';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import routes from 'routes.js';
import moment from 'moment-timezone';
import authImg from 'assets/img/auth/auth.png';

const Auth = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user_timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [botBadgeId, setBotBadgeId] = useState('');
  const [rememberMe] = useState(false);
  const [error, setError] = useState('');
  const [timezones, setTimezones] = useState([]);
  const navigate = useNavigate();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      }
      return null;
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    console.log('Register button clicked');
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
  
    try {
      const response = await axiosInstance.post('/signup', {
        name: username,
        email,
        password,
        bot_badge_id: botBadgeId,
        timezone: user_timezone,
      });
  
      const { access_token } = response.data;
      console.log('Registration successful', response.data);
  
      if (rememberMe) {
        localStorage.setItem('access_token', access_token);
      } else {
        sessionStorage.setItem('access_token', access_token);
      }
  
      navigate('/admin/default');
    } catch (err) {
      if (err.response) {
        console.error('Response error:', err.response.data);
        setError(err.response.data.message || 'Failed to register. Please check your credentials.');
      } else if (err.request) {
        console.error('Request error:', err.request);
        setError('Network error: No response received from server. Please try again later.');
      } else {
        console.error('Error:', err.message);
        setError(err.message || 'An unexpected error occurred. Please try again later.');
      }
    }
  };
  

  useEffect(() => {
    document.documentElement.dir = 'create-user';
    setTimezones(moment.tz.names());
  }, []);

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className="mx-auto min-h-screen">
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(routes)}
                  <Route path="/" element={<Navigate to="/auth/register" replace />} />
                </Routes>
                <div className="flex min-h-screen relative">
                  <div className="flex h-screen w-full items-center justify-center px-4">
                    <div className="w-full max-w-lg flex-col items-center p-8 rounded-lg shadow-lg">
                      <h4 className="mb-4 text-4xl font-bold text-navy-700 dark:text-white text-center">
                        Sign Up
                      </h4>
                      <p className="mb-8 text-center text-base text-gray-600">
                        Enter your details to create your account!
                      </p>
                      {error && <p className="mb-4 text-red-500">{error}</p>}
                      <form onSubmit={handleRegister} className="w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Name*"
                          placeholder="Full Name"
                          id="name"
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Email*"
                          placeholder="example@gmail.com"
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Password*"
                          placeholder="••••••••"
                          id="password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Confirm Your Password*"
                          placeholder="••••••••"
                          id="confirm-password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div className="mb-3">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Select Your Timezone*
                          </label>
                          <select
                            id="timezone"
                            value={user_timezone}
                            onChange={(e) => setTimezone(e.target.value)}
                            className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm"
                          >
                            {timezones.map((timezone) => (
                              <option key={timezone} value={timezone}>
                                {timezone}
                              </option>
                            ))}
                          </select>
                        </div>
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label="Bot Badge ID"
                          placeholder="Eg. S7262-27363-w62221-1"
                          id="bot-badge-id"
                          type="text"
                          value={botBadgeId}
                          onChange={(e) => setBotBadgeId(e.target.value)}
                        />
                        <button
                          type="submit"
                          className="linear mt-2 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                          Sign Up
                        </button>
                      </form>
                      <div className="mt-4 text-center">
                        <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                          Already have an account?
                        </span>
                        <Link
                          to="/auth/signin"
                          className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        >
                          Log in.
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                  className="hidden md:flex flex-shrink-0 w-[49vw] 2xl:w-[44vw] bg-cover lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                  style={{ backgroundImage: `url(${authImg})` }}
                />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default Auth;
