import React, { useState, useEffect, useRef } from "react";
import { IoSend, IoEllipsisVertical, IoClose } from "react-icons/io5";
import AxiosInstance from 'axiosConfig'; // Adjust import based on your file structure
import { getItem } from "utils/localStorage"; // Ensure this is correctly imported based on your project structure

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [settings, setSettings] = useState({
    is_translation_on: JSON.parse(localStorage.getItem("is_translation_on")) || false,
    enforces_t_n_c: JSON.parse(localStorage.getItem("enforces_t_n_c")) || false,
    dis_engages_after: JSON.parse(localStorage.getItem("dis_engages_after")) || 30,
    gen_ai_enabled: JSON.parse(localStorage.getItem("gen_ai_enabled")) || false,
  });
  const [showSettings, setShowSettings] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const userMessage = {
      id: messages.length,
      user: "user-b",
      text: input,
      timestamp: new Date().toISOString(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsTyping(true);

    try {
      const response = await AxiosInstance.post("/simulate-response", {
        message: input,
        ...settings,
      });
      const apiMessage = {
        id: messages.length + 1,
        user: "user-a",
        text: response.data.message_reply,
        timestamp: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, apiMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSettingsChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setSettings((prevSettings) => {
      const newSettings = { ...prevSettings, [name]: newValue };
      localStorage.setItem(name, JSON.stringify(newSettings[name]));
      return newSettings;
    });
  };

  const handleCloseChat = () => {
    window.location.reload(); // Refresh the page
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="w-full h-full flex flex-col bg-gray-100 relative" style={{ zIndex: 2147483647 }}>
      <div className="flex items-center justify-between bg-blue-600 text-white p-4">
        <div className="flex items-center">
          <h2 className="text-xl font-bold">{getItem("bot_name")}</h2>
        </div>
        <button onClick={handleCloseChat} className="text-white mr-2">
            <IoClose className="w-6 h-6" />
          </button>
        <button onClick={() => setShowSettings(!showSettings)} className="text-white">
          <IoEllipsisVertical className="w-6 h-6" />
        </button>
        {showSettings && (
          <div className="absolute top-16 right-0 bg-gray-800 border border-gray-300 rounded-lg shadow-lg p-4 w-64">
            <h3 className="font-bold mb-2 text-white">Settings</h3>
            <div className="mb-2">
              <label className="block text-sm text-white font-bold">
                <input
                  type="checkbox"
                  name="is_translation_on"
                  checked={settings.is_translation_on}
                  onChange={handleSettingsChange}
                  className="mr-2"
                />
                Enable Translation
              </label>
            </div>
            <div className="mb-2">
              <label className="block text-sm text-white">
                <input
                  type="checkbox"
                  name="enforces_t_n_c"
                  checked={settings.enforces_t_n_c}
                  onChange={handleSettingsChange}
                  className="mr-2"
                />
                Enforce Terms & Conditions
              </label>
            </div>
            <div className="mb-2">
              <label className="block text-sm text-white">
                Disengage After (minutes):
                <input
                  type="number"
                  name="dis_engages_after"
                  value={settings.dis_engages_after}
                  onChange={handleSettingsChange}
                  className="ml-2 border border-gray-300 rounded-lg px-2 py-1 text-black"
                />
              </label>
            </div>
            <div className="mb-2">
              <label className="block text-sm text-white">
                <input
                  type="checkbox"
                  name="gen_ai_enabled"
                  checked={settings.gen_ai_enabled}
                  onChange={handleSettingsChange}
                  className="mr-2"
                />
                Enable AI Integration
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.user === "user-b" ? "justify-end" : "justify-start"} mb-2`}
          >
            <div
              className={`max-w-xs p-3 rounded-lg ${message.user === "user-b" ? "bg-blue-500 text-white text-right" : "bg-gray-200 text-left"}`}
            >
              {message.text}
              <div className="text-xs text-gray-500 mt-1">
                {new Date(message.timestamp).toLocaleString()}
              </div>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="flex justify-start mb-2">
            <div className="flex items-center">
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce mr-2"></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce mr-2"></div>
              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
            </div>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>
      <div className="flex items-center p-2 bg-white border-t border-gray-300">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none"
          placeholder="Type a message..."
        />
        <button
          onClick={handleSendMessage}
          className="ml-2 p-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none"
        >
          <IoSend className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
