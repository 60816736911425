import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import FrontendLayout from "layouts/frontend";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import routes from "routes";
import { renderRoutes } from "layouts/admin";
import Auth from "views/auth/Register";
import useIdleTimeout from "hooks/useIdleTimeout";

const App = () => {
  useIdleTimeout(3540000); // 59 minutes in milliseconds
  return (
    <Routes>
      {renderRoutes(routes)}
      <Route path="auth/register" element={<Auth />} />
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/*" element={<FrontendLayout />} />
      <Route path="/" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default App;