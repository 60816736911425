import React from "react";
import styled from "styled-components";
import defaultCardImage from "../../images/shield-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-2.svg";
import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0; 
  margin: 0;
  box-sizing: border-box; 
`;

const ThreeColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const VerticalSpacer = styled.div`
  margin-top: 2.5rem;
  width: 100%;
`;

const Column = styled.div`
  flex: 1;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  
  @media (min-width: 768px) {
    flex: 1 1 50%;
  }
  
  @media (min-width: 1024px) {
    flex: 1 1 33.3333%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0.5rem;
  padding: 2rem 1rem;
  box-sizing: border-box;
  
  @media (min-width: 640px) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  text-align: center;

  .imageContainer {
    border: 1px solid;
    text-align: center;
    border-radius: 50%;
    padding: 1.25rem;
    flex-shrink: 0;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .textContainer {
    margin-left: 1rem;
    margin-top: 1rem;
    @media (min-width: 640px) {
      margin-top: 0.5rem;
    }
  }

  .title {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
  }

  .description {
    margin-top: 0.5rem;
    font-size: 1.125rem;
    color: #4a4a4a;
    line-height: 1.75rem;
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25rem;
  opacity: 0.25;
  transform: translate(8rem, 12rem);
`;

const FeaturesSection = ({ cards = null, heading = "Amazing Solutions", subheading = "Features", description = "QualBots' AI bots deliver real-time, personalized interactions by analyzing customer behavior and preferences. Enhance customer satisfaction, loyalty, and business growth with seamless engagement." }) => {
  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Customer Engagement",
      description: "QualBots' AI bots deliver real-time, personalized interactions by analyzing customer behavior and preferences. Enhance customer satisfaction, loyalty, and business growth with seamless engagement."
    },
    { imageSrc: SupportIconImage, title: "Service Fulfillment", description: "Streamline service processes with our chatbots that automate and expedite tasks like order management and request processing, integrating smoothly with your systems for efficiency and ease." },
    { imageSrc: CustomizeIconImage, title: "Customizable Solutions", description: "Tailor our chatbots to meet your unique business needs with customizable features. Whether you require specific functionalities or integrations, our AI solutions can be adapted to fit your exact requirements, ensuring a perfect match for your business operations."},
    { imageSrc: ReliableIconImage, title: "Booking Reservations", description: "Manage bookings effortlessly with our chatbots, which handle reservations, schedule management, and confirmations for hotels, restaurants, and events, integrating seamlessly with your systems." },
    { imageSrc: FastIconImage, title: "Expert Knowledge Clone", description: "Create an expert knowledge clone with AI-powered chatbots that deliver specialized advice and insights, ideal for industries needing detailed, expert-level support." },
    { imageSrc: SimpleIconImage, title: "Lead Generation", description: "Boost your business growth with our chatbots that excel in lead generation. They engage visitors, capture contact information, and qualify leads through intelligent interactions, seamlessly integrating with your CRM to enhance your sales pipeline." },
    { imageSrc: FastIconImage, title: "Healthcare Assistance", description: "QualBots provide intelligent healthcare solutions, offering patient triage, appointment scheduling, and symptom checking, enhancing the efficiency of healthcare providers while offering personalized care." },
    { imageSrc: ReliableIconImage, title: "Financial Transactions", description: "Streamline payment processes for banks and fintech firms with bots that assist in account inquiries, transaction tracking, and loan processing, ensuring secure and real-time service for customers." },
    { imageSrc: CustomizeIconImage, title: "E-Commerce Optimization", description: "Enhance your e-commerce platform with chatbots that offer personalized product recommendations, guide users through checkout processes, and handle customer inquiries, improving user experience and driving sales." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Qualbots-Built for you!"}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default FeaturesSection;
