import React, { useState } from 'react'
import { Button } from '@chakra-ui/react';
import { FormControl, FormErrorMessage, Select } from '@chakra-ui/react';
import CustomForm from './customForm';

const AddBooking = () => {
  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})

  const selectedOption = ({ booking: '' })

  const [selectData, setSelectData] = useState(selectedOption)

  const validateForm = (data) => {
    let newErrors = {}

    formFields.forEach((field) => {
        if (!data[field.name]) {
            newErrors[field.name] = `${field.label} is required`
        }
    })
    if (!data.booking) {
        newErrors.variable = 'Variable Type is Required'
    }

    return newErrors
}


  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: '' })
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const fullForm = { ...formData, ...selectData }
    const newErrors = validateForm(fullForm)
    setErrors(newErrors)
    console.log('Form Submitted:', fullForm)
  }

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setSelectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: '' })
  };


  const formFields = [
    { name: 'item', label: 'Item ID', placeholder: 'E.g Plate Number', type: 'text' },
    { name: 'desc', label: 'Brief Item Description', type: 'text' },
    { name: 'slots', label: 'Item Slots Count', type: 'text' },
    { name: 'starting', label: 'Starting Venue', placeholder: 'E.g Nairobi', type: 'text' },
    { name: 'stopping', label: 'Stopping Venue', placeholder: 'E.g Kampala', type: 'text' }
  ]

  return (

    <div className="bg-white rounded-lg shadow-md p-6 mt-4">

      <p className='text-xl font-normal mt-2 mb-5'>Add an Item For Booking</p>

      <form onSubmit={handleSubmit}>

        <CustomForm formFields={formFields} onChange={handleChange} onSubmit={handleSubmit} formData={formData} errors={errors}/>

       <FormControl isInvalid={errors.booking}>
       <Select
          name='booking'
          value='selectData.value'
          onChange={handleSelect}
          iconColor='transparent'
          placeholder='Select Item Type'
          className='p-2 w-3/12 border border-indigo-500 focus:outline-indigo rounded-lg bg-white mb-7'>
          <option value='Event Bookingn'>Event Booking</option>
          <option value='Property Booking'>Property Booking</option>
        </Select>
        <FormErrorMessage className="text-red-600 text-lg mb-7">{errors.booking}</FormErrorMessage>
       </FormControl>
       

        <Button type='submit' className='bg-indigo-200 py-2 px-6 text-lg rounded-lg hover:bg-indigo-500'>Save Item</Button>

      </form>



    </div>

  )
}

export default AddBooking