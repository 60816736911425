import Card from "components/card";
import { DiApple, DiAndroid, DiWindows } from "react-icons/di";
import ViewResponse from "./ViewResponse";
import React, { useState, useMemo } from "react";
import AxiosInstance from 'axiosConfig';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const DevelopmentTable = (props) => {
  const { columnsData, tableData } = props;
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewClick = async (rowData) => {
    try {
      const response = await AxiosInstance.post('/view-response', {
        for_message_id: rowData.message_id,
        from_sender: rowData.sender,
        channel_name: rowData.channel,
      });
      setModalData(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = useMemo(() => {
    return [
      ...columnsData,
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            <button
              onClick={() => handleViewClick(row.original)}
              className="text-blue-500 hover:underline cursor-pointer"
            >
              View
            </button>
            <span> | </span>
            <a href="/admin/bot-studio" className="text-blue-500 hover:underline">
              Train
            </a>
          </div>
        ),
      },
    ];
  }, [columnsData]);

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 12 }, // Set initial page size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of 'rows', we'll use 'page'
    prepareRow,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = tableInstance;

  return (
    <>
      <Card extra={"w-full h-full p-4"}>
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Messages Received
          </div>
        </div>

        <div className="h-full overflow-x-auto">
          <table
            {...getTableProps()}
            className="mt-8 w-full border-collapse"
            variant="simple"
            color="gray-500"
            mb="24px"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-8 pb-[10px] text-left dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-600">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"}
                  >
                    {row.cells.map((cell, index) => {
                      let data = cell.value;
                      if (cell.column.Header === "VIA") {
                        data = (
                          <div className="flex items-center justify-center gap-2">
                            {cell.value === "channel" && (
                              <div className="text-[22px] text-gray-600 dark:text-white">
                                <DiApple />
                              </div>
                            )}
                            {cell.value === "android" && (
                              <div className="text-[21px] text-gray-600 dark:text-white">
                                <DiAndroid />
                              </div>
                            )}
                            {cell.value === "windows" && (
                              <div className="text-xl text-gray-600 dark:text-white">
                                <DiWindows />
                              </div>
                            )}
                          </div>
                        );
                      }
                      if (cell.column.Header === "Actions") {
                        data = (
                          <div>
                            <button
                              onClick={() => handleViewClick(row.original)}
                              className="text-blue-500 hover:underline cursor-pointer"
                            >
                              View
                            </button>
                            <span> | </span>
                            <a
                              href="/admin/bot-studio"
                              className="text-blue-500 hover:underline"
                            >
                              Train
                            </a>
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-3 text-[14px] text-left"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination controls */}
        <div className="flex justify-between mt-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={`${
              !canPreviousPage && "cursor-not-allowed opacity-50"
            } border border-gray-300 rounded-md px-4 py-2 text-gray-700 bg-white hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none`}
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={`${
              !canNextPage && "cursor-not-allowed opacity-50"
            } border border-gray-300 rounded-md px-4 py-2 text-gray-700 bg-white hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none`}
          >
            Next
          </button>
        </div>
      </Card>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 max-w-md w-full relative">
            <button
              className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700"
              onClick={() => setIsModalOpen(false)}
            >
              X
            </button>
            <ViewResponse response={modalData} />
          </div>
        </div>
      )}
    </>
  );
};

export default DevelopmentTable;
