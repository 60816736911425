import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const useIdleTimeout = (timeoutDuration) => {
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      // Redirect to sign-in page after timeout
      navigate("/auth/sign-in");
    }, timeoutDuration);
  }, [navigate, timeoutDuration]);

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Initialize timer
    resetTimer();

    return () => {
      // Clean up event listeners and timer on component unmount
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [resetTimer]);

  return resetTimer;
};

export default useIdleTimeout;
