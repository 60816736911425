import React, { useRef } from "react";
import Card from "../../../../components/card";
import { useGlobalFilter, useSortBy, useTable } from "react-table";

const ReportTable = ({
  columnsData,
  tableData,
  reportName,
  switchForms,
  handleFormSwitch,
}) => {
  const tableRef = useRef();
  const columns = React.useMemo(() => columnsData, [columnsData]);
  const data = React.useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const tableToExcel = (function () {
    var style = "<style>.green { background-color: lightgrey; }</style>";
    var uri = "data:application/vnd.ms-excel;base64,";
    var template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
      style +
      "</head><body><h1>{reportName}</h1><table>{table}</table></body></html>";
    var base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    var format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
    return function (table, name) {
      if (!table.nodeType) table = document.getElementById(table);
      var ctx = {
        worksheet: name || "Worksheet",
        reportName: name,
        table: table.innerHTML,
      };
      window.location.href = uri + base64(format(template, ctx));
    };
  })();

  return (
    <Card extra={"w-full h-full p-4"}>
      {/* Header Row */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex flex-col">
          <div className="text-xl font-bold text-navy-700 text-black">
            {reportName}
          </div>
          <div className="flex items-center">
            <button
              className="bg-blue-500 text-white px-3 py-1 rounded mr-2"
              onClick={() => tableToExcel(tableRef.current, reportName)}
            >
              Export to Excel
            </button>
            <select
              className="bg-gray-200 px-3 py-1 rounded"
              style={{ color: "black" }}
              onChange={(e) => handleFormSwitch(e.target.value)}
            >
              <option value="">Select Form</option>
              {Object.entries(switchForms).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* Table Body */}
      <div className="overflow-x-auto">
        <table
          {...getTableProps()}
          ref={tableRef}
          className="w-full border-collapse border border-gray-300"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    className="border border-gray-300 bg-gray-200 px-2 py-1 text-xs text-left"
                    style={{ color: "black" }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                  style={{ color: "black" }}
                >
                  {row.cells.map((cell) => (
                    <td
                      className="border border-gray-300 px-2 py-1 text-xs whitespace-normal break-words"
                      {...cell.getCellProps()}
                      key={cell.column.id}
                      style={{ color: "black" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ReportTable;
