import Banner from "./components/Banner";

const ProfileOverview = () => {
  return (
    <div className="flex w-full flex-col items-center gap-5">
      <div className="w-full mt-3 flex flex-col gap-14 lg:w-8/12 lg:grid lg:grid-cols-1">
        <div className="lg:!mb-0">
          <Banner />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;
