import React from 'react';

const ErrorLogTable = ({ errorLogs }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Timestamp</th>
            <th className="py-2 px-4 border-b">Channel</th>
            <th className="py-2 px-4 border-b">Sender ID</th>
            <th className="py-2 px-4 border-b">Err Code</th>
            <th className="py-2 px-4 border-b">Variables</th>
            <th className="py-2 px-4 border-b">RCA Func</th>
            <th className="py-2 px-4 border-b">Err Desc</th>
          </tr>
        </thead>
        <tbody>
          {errorLogs.map((log, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
              <td className="py-2 px-4 border-b">{log.timestamp}</td>
              <td className="py-2 px-4 border-b">{log.channel}</td>
              <td className="py-2 px-4 border-b">{log.sender_id}</td>
              <td className="py-2 px-4 border-b">{log.err_code}</td>
              <td className="py-2 px-4 border-b">{log.variables}</td>
              <td className="py-2 px-4 border-b">{log.rca_func}</td>
              <td className="py-2 px-4 border-b">{log.err_desc}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ErrorLogTable;
