import React, { useState, useRef, useEffect } from 'react';
import { IoMdArrowDropdown, IoMdMenu } from 'react-icons/io';
import qualbotTextImage from '../images/Qual-logo.png';
import '../../../assets/css/App.css'; 

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDarkMode] = useState(false);

  const solutionsRef = useRef(null);
  const resourcesRef = useRef(null);
  const companyRef = useRef(null);
  const Partners = useRef(null);
  const Pricing = useRef(null);
  const RequestDemo = useRef(null);

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ![solutionsRef.current, resourcesRef.current, companyRef.current].some(
          (ref) => ref && ref.contains(event.target)
        )
      ) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`bg-${isDarkMode ? '[#cbd5e0]' : 'gray-200'} text-${isDarkMode ? 'white' : 'black'} flex justify-between items-center px-4 md:px-6 py-2 md:py-3 sticky top-0 z-50`}>
      <div className="flex items-center space-x-4">
        <img
          src={qualbotTextImage}
          alt="QualBot logo"
          className="logo w-60 md:w-65 h-24 md:h-28 object-cover object-center"
        />
      </div>
      <button onClick={handleMobileMenuToggle} className="md:hidden text-lg">
        <IoMdMenu className="w-6 h-6 md:w-8 md:h-8" />
      </button>

      <div className={`flex-col md:flex md:flex-row md:space-x-8 ${isMobileMenuOpen ? 'flex' : 'hidden'} space-y-2 md:space-y-0 absolute md:relative top-12 md:top-0 left-0 md:left-auto md:items-center w-full md:w-auto bg-${isDarkMode ? '0b1437' : 'gray-200'} md:bg-transparent`}>

      <div className="relative" ref={solutionsRef}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('solutions')}
          >
            Home
          </p>
        </div>
        <div className="relative" ref={solutionsRef}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('solutions')}
          >
            Services
          </p>
        </div>

        <div className="relative" ref={resourcesRef}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('resources')}
          >
            Resources
            <IoMdArrowDropdown className="inline-block ml-1" />
          </p>
          {openMenu === 'resources' && (
            <div className={`absolute top-full right-0 bg-${isDarkMode ? '0b1437' : 'gray-100'} text-${isDarkMode ? 'white' : 'black'} border border-gray-400 rounded-lg shadow-lg mt-2 z-50`}>
              <p className="p-3 cursor-pointer hover:text-[#f0a500] hover:font-bold">Testimonial Videos</p>
              <p className="p-3 cursor-pointer hover:text-[#f0a500] hover:font-bold">Success Stories</p>
              <p className="p-3 cursor-pointer hover:text-[#f0a500] hover:font-bold">Developer Portal</p>
            </div>
          )}
        </div>

        <div className="relative" ref={companyRef}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('company')}
          >
            Company
            <IoMdArrowDropdown className="inline-block ml-1" />
          </p>
          {openMenu === 'company' && (
            <div className={`absolute top-full right-0 bg-${isDarkMode ? '0b1437' : 'gray-100'} text-${isDarkMode ? 'white' : 'black'} border border-gray-400 rounded-lg shadow-lg mt-2 z-50`}>
              <p className="p-3 cursor-pointer hover:text-[#f0a500] hover:font-bold">About Us</p>
              <p className="p-3 cursor-pointer hover:text-[#f0a500] hover:font-bold">Careers</p>
            </div>
          )}
        </div>

        <div className="relative" ref={Partners}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('partners')}
          >
            Partners
          </p>
        </div>

        <div className="relative" ref={Pricing}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('pricing')}
          >
            Pricing
          </p>
        </div>
        <div className="relative" ref={RequestDemo}>
          <p
            className={`text-lg md:text-xl font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('requestDemo')}
          >
            Request Demo
          </p>
        </div>
        <div className="relative">
          <a
            href="/auth/signIn"
            className={`border border-[#0b1437] bg-[#0b1437] text-white py-3 px-6 rounded-lg text-lg md:text-xl hover:bg-[#f0a500] hover:text-[#0b1437] transition-colors duration-300`}
          >
            Log In
          </a>
        </div>

      </div>
    </div>
  );
};

export default TopBar;
