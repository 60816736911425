import React from "react";
import { motion } from "framer-motion";
import useInView from "../helpers/useInView";

const containerStyle = {
  fontFamily: 'custom-font', 
  minHeight: '100vh',
  color: '#6b7280',
  padding: '2rem',
  overflow: 'hidden',
};

function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = children.map((child, i) => (
    <AnimatedSlideInComponent
      key={i}
      direction={directions[i % directions.length]}
    >
      {child}
    </AnimatedSlideInComponent>
  ));
  
  return <>{childrenWithAnimation}</>;
}

function AnimatedSlideInComponent({ direction = "left", offset = 30, children }) {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px` });

  const x = { target: "0%" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{ x: inView ? x.target : x.initial }}
        transition={{ type: "spring", damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
}

const App = (props) => (
  <div style={containerStyle} className="App">
    <AnimationReveal {...props} />
  </div>
);

export default App;
