import Axios from "axios";
import { getItem, removeItem } from "utils/localStorage";

// Creating and exporting a default Axios instance
const axiosInstance = Axios.create({
  baseURL: "https://www.vassbot.com/apis", // Base URL for all requests
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json, text/plain, */*",
  },
  timeout: 20000, // Increased timeout to 20 seconds
});

// Adding request interceptors to dynamically set the Authorization header
axiosInstance.interceptors.request.use(
  config => {
    const token = getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Adding response interceptors
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Log the error details
    console.error("Axios error:", error);
    // Handle specific error codes
    if (error.response) {
      const { status } = error.response;
      if ([401].includes(status)) {
        // Redirect to home page if token has expired or if the request is unprocessable
        removeItem("access_token");
        removeItem("refreshed_at");
        window.location.href = "/";
      }
      else if([422].includes(status)){
        //refresh the webpage
        //window.location.href = "/admin/default";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
