import React, { useMemo, useState, useEffect } from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import AxiosInstance from 'axiosConfig';

const CheckTable = () => {
  const [tableData, setTableData] = useState([]);
  const [columnsData] = useState([
    {
      Header: 'ID',
      accessor: 'task_id',
    },
    {
      Header: 'Task',
      accessor: 'task_name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'OpenDate',
      accessor: 'creation_date',
    },
    {
      Header: 'Priority',
      accessor: 'priority',
    },
    {
      Header: 'DueDate',
      accessor: 'due_date',
    }
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get("/get-my-tasks");
        if (response.data.status === "success") {
          setTableData(response.data.tasks_list);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 6, // Set initial page size to 6
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex},
  } = tableInstance;

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          My Pending Tasks
        </div>
        <CardMenu />
      </header>

      <div className="mt-8 max-h-[400px] overflow-auto xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full border-separate border-spacing-0"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start bg-gray-100 dark:bg-gray-700 text-xs font-bold tracking-wide text-gray-600 dark:text-white"
                    key={index}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : "bg-white dark:bg-gray-900"}
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      key={index}
                      className="p-2 text-sm text-gray-700 dark:text-gray-300"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="py-1 px-3 bg-gray-200 dark:bg-gray-800 rounded text-gray-700 dark:text-gray-300"
          >
            Previous
          </button>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="py-1 px-3 bg-gray-200 dark:bg-gray-800 rounded text-gray-700 dark:text-gray-300"
          >
            Next
          </button>
        </div>
      </div>
    </Card>
  );
};

export default CheckTable;