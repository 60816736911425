import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import authImg from 'assets/img/auth/auth.png';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import axiosInstance from '../../../../axiosConfig';
import InputField from 'components/fields/InputField';

export default function CreateUser() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const mainRef = useRef(null);

  useEffect(() => {
    let resizeTimeout;

    const handleResize = (entries) => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }

      resizeTimeout = setTimeout(() => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            const width = entry.contentRect.width;
            console.log("Width of observed element:", width);
          }
        }
      }, 100); // Debounce time of 100ms
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (mainRef.current) {
      resizeObserver.observe(mainRef.current);
    }

    return () => {
      clearTimeout(resizeTimeout);
      resizeObserver.disconnect();
    };
  }, []);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setError("");  // Reset error state
    setSuccess(false);
  
    try {
      const response = await axiosInstance().post("/create-user", {
        email,
        name: username,
        passphrase: password
      });
  
      console.log("User creation successful", response.data);
  
      setSuccess(true);
      setTimeout(() => {
        setUsername("");
        setEmail("");
        setPassword("");
        setSuccess(false);
        navigate("/");
      }, 3000);
  
    } catch (err) {
      console.error("User creation error:", err);
      // Clear the input fields on error
      setUsername("");
      setEmail("");
      setPassword("");
  
      if (err.response && err.response.data && err.response.data.message === "User already exists") {
        setError("User already exists. Please check your input.");
      } else {
        setError("Failed to create user. Please check your input.");
      }
    }
  };
  

  return (
    <div ref={mainRef}>
      <FixedPlugin />
      <main className={`mx-auto min-h-screen`}>
        <div className="relative flex">
          <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
            <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              
              {/* Main Div */}
              <div className="flex min-h-screen relative">
                {/* {Create User section} */}
                <div className="flex h-screen w-full items-center justify-center px-4 dark:text-white">
                  <div className="w-full max-w-lg flex-col items-center p-8 bg-white rounded-lg shadow-lg dark:bg-navy-800">
                    <h4 className="mb-4 text-4xl font-bold text-navy-700 dark:text-white text-center">
                      Create User
                    </h4>
                    <p className="mb-8 text-center text-base text-gray-600 dark:text-gray-300">
                      Enter the details to create a new user!
                    </p>
                    <form onSubmit={handleCreateUser} className="w-full">
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Username*"
                        placeholder="Full Name"
                        id="username"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        labelClassName="dark:text-white"
                      />
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="User's Email*"
                        placeholder="example@gmail.com"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        labelClassName="dark:text-white"
                      />
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Password*"
                        placeholder="••••••••"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        labelClassName="dark:text-white"
                      />
                      {error && <p className="text-red-500">{error}</p>}
                      {success && <p className="text-green-500">User created successfully! Redirecting...</p>}
                      <button
                        type="submit"
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Create User
                      </button>
                    </form>
                  </div>
                </div>
                {/* auth image */}
                <div className="hidden md:flex flex-shrink-0 w-[49vw] 2xl:w-[44vw] bg-cover lg:rounded-bl-[120px] xl:rounded-bl-[200px]" style={{ backgroundImage: `url(${authImg})` }} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
