import React, { useState, useEffect, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { MdArrowDropUp } from 'react-icons/md';
import Card from 'components/card';
import AxiosInstance from 'axiosConfig';

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MessagingActivity = ({ botId }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Messages',
      data: [],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }],
  });

  const fetchMessagingActivity = useCallback(async () => {
    if (!botId) return; // Exit early if botId is not available

    try {
      const response = await AxiosInstance.post('/messaging-activity-trends', {
        "duration_in": "months",
        "stats_date_from": "12/12/2022"
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch messaging activity');
      }

      const data = response.data;

      // Transform the data into the format expected by the chart
      const labels = Object.keys(data);
      const values = Object.values(data);

      setChartData({
        labels,
        datasets: [{
          label: 'Messages',
          data: values,
          backgroundColor: '#4318ff',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        }],
      });
    } catch (error) {
      console.error('Error fetching messaging activity:', error);
    }
  }, [botId]); // Add botId to the dependencies array

  useEffect(() => {
    console.log('Fetching messaging activity for botId:', botId); // Debugging line
    fetchMessagingActivity();
  }, [fetchMessagingActivity, botId]); // Ensure botId changes trigger data fetching

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-[20px] font-bold text-navy-700 dark:text-white">
            Messaging Activity
          </p>
          <p className="text-sm font-medium leading-4 text-gray-600">
            campaign sent 2 days ago
          </p>
        </div>
        <div className="mt-2 flex items-start">
          <div className="flex items-center text-sm text-green-500">
            <MdArrowDropUp className="h-5 w-5" />
            <p className="font-bold"> +50% </p>
          </div>
        </div>
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: '#a3a3a3',
                },
              },
              y: {
                grid: {
                  color: '#e6e6e6',
                },
                ticks: {
                  color: '#a3a3a3',
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
      <span className="text-sm font-medium leading-6 text-gray-600">
        Messages Count Per Month
      </span>
    </Card>
  );
};

export default MessagingActivity;
