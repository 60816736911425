import React, { useState, useEffect, useCallback } from "react";
import AxiosInstance from "../../../axiosConfig";
import ReportTable from "./components/ReportTable";
import "utils/loadingSpinner.css"; // Correctly import the CSS file

const createJsonMapping = (fields) =>
  fields.map((field) => ({
    Header: field,
    accessor: field.toLowerCase(),
  }));

const Tables = () => {
  const [tableDataReport, setTableData] = useState([]);
  const [reportName, setReportName] = useState("");
  const [switchForms, setSwitchForms] = useState({});
  const [columnsDataReport, setColumnsDataReport] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (reportNumber = "2") => {
    const requestBody = {
      report_number: "1",
      limit_records_count: 1000,
      order_by: "desc",
    };

    try {
      setLoading(true);
      const response = await AxiosInstance.post("/retrieve-report", {
        ...requestBody,
        report_number: reportNumber,
      });
      if (response && response.data) {
        const { current_report, switch_forms } = response.data;
        const tableDataReport = current_report?.report_data || [];
        const reportName = current_report?.report_name || "";
        const fields_list =
          current_report?.report_labels || ["no_labels1", "no_labels2"];

        setTableData(tableDataReport);
        setReportName(reportName);
        setSwitchForms(switch_forms || {});
        setColumnsDataReport(createJsonMapping(fields_list));
        setError(null);
      } else {
        setError("Invalid response from API.");
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
      setError("Failed to fetch report. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array means fetchData will only be created once

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {loading ? (
        <div className="spinner-overlay">
        <div className="spinner"></div>
        <div className="loading-text">Loading Report...</div>
      </div>
        
      ) : (
        <>
          {error && <div>Error: {error}</div>}
          <ReportTable
            reportName={reportName}
            switchForms={switchForms}
            handleFormSwitch={fetchData}
            columnsData={columnsDataReport}
            tableData={tableDataReport}
          />
        </>
      )}
    </div>
  );
};

export default Tables;
