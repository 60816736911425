import React from "react";

// Admin Imports
import MainDashboard from "./views/admin/default";
import BotStudio from "./views/admin/BotStudio";
import BotVisitors from "./views/admin/BotVisitors";
import ReportView from "./views/admin/ReportView";
import Messages from "./views/admin/Messages";
import Profile from "./views/admin/profile";
import Tools from "./views/admin/Tools";

//Frontend imports
import Home from 'views/frontend/Home';
import Products from 'views/frontend/Products';
import Partners from 'views/frontend/Partners';
import Pricing from 'views/frontend/Pricing';
import Contacts from 'views/frontend/Contacts';

// Bot Studio Components
import AddBooking from "./views/admin/BotStudio/components/addBooking";
import AddConvo from "./views/admin/BotStudio/components/addConvo";
import Channel from "./views/admin/BotStudio/components/channel";
import CreateBookings from "./views/admin/BotStudio/components/createBookings";
import CustomForm from "./views/admin/BotStudio/components/customForm";
import DevelopmentTable from "./views/admin/BotStudio/components/DevelopmentTable";
import ReviewConvo from "./views/admin/BotStudio/components/reviewConvo";
import ReviewNode from "views/admin/BotStudio/components/reviewNode";
import AddBulkNodes from "views/admin/BotStudio/components/addBulkNodes";
import GlobalConfigs from "views/admin/BotStudio/components/globalConfigs";
import CreateUser from "views/admin/BotStudio/components/create_user";


// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdMessage,
  MdReportGmailerrorred,
  MdSettings,
} from "react-icons/md";
import Admin from "layouts/admin";


const routes = [
   // Admin Routes
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <Admin>
        <MainDashboard />
      </Admin>
    ),
  },

  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/channel",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: (
      <Admin>
        <Channel />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/add-booking",
    component: (
      <Admin>
        <AddBooking />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/add-Bulk-Nodes",
    component: (
      <Admin>
        <AddBulkNodes />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/add-convo",
    component: (
      <Admin>
        <AddConvo />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/create-bookings",
    component: (
      <Admin>
        <CreateBookings />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/create-user",
    component: (
      <Admin>
        <CreateUser />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/custom-form",
    component: (
      <Admin>
        <CustomForm />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/development-table",
    component: (
      <Admin>
        <DevelopmentTable />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/global-configs",
    component: (
      <Admin>
        <GlobalConfigs />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/review-convo",
    component: (
      <Admin>
        <ReviewConvo />
      </Admin>
    ),
  },
  {
    name: "channel",
    layout: "/admin",
    show: true,
    path: "bot-studio/review-node",
    component: (
      <Admin>
        <ReviewNode />
      </Admin>
    ),
  },
  {
    name: "Bot Studio",
    layout: "/admin",
    path: "bot-studio",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: (
      <Admin>
        <BotStudio />
      </Admin>
    ),
    secondary: true,
    children: [
      
    ],
  },
  {
    name: "Bot Visitors",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "bot-visitors",
    component: (
      <Admin>
        <BotVisitors />
      </Admin>
    ),
  },
  {
    name: "Report View",
    layout: "/admin",
    icon: <MdReportGmailerrorred className="h-6 w-6" />,
    path: "report-view",
    component: (
      <Admin>
        <ReportView />
      </Admin>
    ),
  },
  {
    name: "Messages",
    layout: "/admin",
    path: "messages",
    icon: <MdMessage className="h-6 w-6" />,
    component: (
      <Admin>
        <Messages />
      </Admin>
    ),
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: (
      <Admin>
        <Profile />
      </Admin>
    ),
  },
  {
    name: "Tools",
    layout: "/admin",
    path: "tools",
    icon: <MdSettings className="h-6 w-6" />,
    component: (
      <Admin>
        <Tools />
      </Admin>
    ),
  },
  // frontend imports
  {
    name: 'Home',
    layout: '/',
    path: 'home',
    component: <Home />,
  },
  {
    name: 'Products',
    layout: '/',
    path: 'products',
    component: <Products />,
  },
  {
    name: 'Partners',
    layout: '/',
    path: 'partners',
    component: <Partners />,
  },
  {
    name: 'Pricing',
    layout: '/',
    path: 'pricing',
    component: <Pricing />,
  },
  {
    name: 'Contacts',
    layout: '/',
    path: 'contacts',
    component: <Contacts />,
  },
];

export default routes;
