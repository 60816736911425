import React, { useState } from "react";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import faqimage from '../FAQ-image.png';
const Container = ({ children, ...props }) => (
  <div className="relative" {...props}>
    {children}
  </div>
);

const ContentWithPaddingXl = ({ children, ...props }) => (
  <div className="max-w-screen-xl mx-auto py-20 lg:py-24 px-4" {...props}>
    {children}
  </div>
);

const FAQSContainer = (props) => <dl className="max-w-4xl relative" {...props} />;
const FAQ = (props) => (
  <div
    className="cursor-pointer select-none mb-16 px-8 sm:px-10 py-10 sm:py-12 rounded-lg text-gray-800 mb-5 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300"
    {...props}
  />
);
const QuestionText = (props) => (
  <span className="text-2xl lg:text-2xl font-semibold leading-loose" {...props} />
);

const Question = (props) => <dt className="flex justify-between items-center mt-8" {...props} />;
const QuestionToggleIcon = (props) => (
  <span className="ml-2 transition duration-300" {...props}>
    <ChevronDownIcon />
  </span>
);

const FAQSection = ({
  faqs = [
    {
      question: "What is QualBots, and how can it help my business?",
      answer: "QualBots provides AI-powered chatbot solutions that enhance customer engagement, streamline service delivery, and drive business growth. Our chatbots can automate customer interactions, provide personalized support, and integrate seamlessly with your existing systems, helping you improve efficiency and customer satisfaction."
    },
    {
      question: "What industries can use QualBots?",
      answer: "QualBots caters to a wide range of industries, including e-commerce, banking, hospitality, healthcare, education, and more. Our marketplace offers specialized chatbots tailored to specific industries and use cases, ensuring that you can find the right solution for your business."
    },
    {
      question: "How do QualBots integrate with existing systems?",
      answer: "Our chatbots are designed to integrate easily with your website, mobile app, CRM, or other business systems through APIs. We provide seamless integration, ensuring that your business operations remain smooth and uninterrupted. If you need help with integration, our support team is available to assist you."
    },
    {
      question: "Are QualBots customizable for my specific business needs?",
      answer: "Absolutely! QualBots are highly customizable, allowing you to tailor their features and functionalities to match your business's unique requirements. Whether it's customer engagement, booking reservations, or specialized consultations, our solutions can be adapted to suit your needs."
    },
    {
      question: "How secure is my data with QualBots?",
      answer: "We take data security seriously. QualBots uses encryption and secure protocols to ensure that customer interactions and sensitive information are protected. Our solutions comply with industry-standard security practices to safeguard your data."
    },
    {
      question: "Can QualBots grow with my business?",
      answer: "Yes! Our solutions are scalable, meaning they can grow alongside your business. Whether you’re expanding to new markets or increasing customer volume, our chatbots can handle the added demand without compromising performance or efficiency."
    },
    {
      question: "How do I get started with QualBots?",
      answer: "Getting started is simple! Explore our marketplace to find a chatbot that suits your needs, or contact us to discuss a custom solution. You can also request a demo to see how QualBots can enhance your business operations. You can contact us 24/7 through our support hotline or email."
    },
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    setActiveQuestionIndex(activeQuestionIndex === questionIndex ? null : questionIndex);
  };

  return (
    <Container className="w-full px-0">
  <ContentWithPaddingXl className="w-full">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full"> 
      {/* Left Column (Image) */}
      <div className="w-full mx-auto flex justify-center items-center overflow-hidden">
        <div className="relative w-full" style={{ paddingTop: '75%' }}> 
          <img
            src={faqimage}
            alt="FAQ"
            className="absolute inset-0 w-full h-full object-contain"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      </div>

      {/* Right Column (FAQ Content) */}
      <FAQSContainer className="w-full">
        {faqs.map((faq, index) => (
          <FAQ key={index} onClick={() => toggleQuestion(index)} className="group">
            <Question>
              <QuestionText>{faq.question}</QuestionText>
              <QuestionToggleIcon
                style={{
                  transform: activeQuestionIndex === index ? "rotate(-180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease"
                }}
              />
            </Question>
            <div
              style={{
                maxHeight: activeQuestionIndex === index ? "200px" : "0px",
                opacity: activeQuestionIndex === index ? 1 : 0,
                overflow: "hidden",
                transition: "max-height 0.3s ease, opacity 0.3s ease",
                paddingBottom: "15px"
              }}
            >
              <p className="mt-4 text-gray-800 text-lg">{faq.answer}</p>
            </div>
          </FAQ>
        ))}
      </FAQSContainer>
    </div>
  </ContentWithPaddingXl>
</Container>

  );
};

export default FAQSection;
