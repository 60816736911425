import React, { useState, useEffect } from 'react';

const ViewResponse = ({ response }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (response) {
      setIsLoading(false);
      setData(response);
      setError(null);
    } else if (response === null) {
      // Do nothing if response is null, wait for it to update
    } else {
      setError(new Error('Response is undefined'));
      setIsLoading(false);
    }
  }, [response]);

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">Error loading response: {error.message}</div>;
  }

  const { message, status } = data || {};

  if (!message || status !== 'success') {
    return <div className="text-red-500 text-center">Failed to load response data.</div>;
  }

  return (
    <div className="p-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-md rounded-md max-w-full overflow-x-auto">
      <h2 className="text-2xl font-bold mb-4 border-b-2 border-white pb-2">Response Details</h2>
      <div className="mb-4">
        <div className="flex justify-between mb-2">
          <div>
            <strong className="text-yellow-300">Sender:</strong> {message.user_id}
          </div>
          <div>
            <strong className="text-yellow-300">Channel:</strong> {message.channel_name}
          </div>
          <div>
            <strong className="text-yellow-300">Replied By:</strong> {message.replied_by}
          </div>
        </div>
      </div>
      <div className="mb-4 bg-dark-200 text-black p-2 rounded-md overflow-x-auto">
        <strong>Sender Message:</strong>
        <p>{message.user_question}</p>
      </div>
      <div className="bg-dark-200 text-black p-2 rounded-md overflow-x-auto">
        <strong>Bot Response:</strong>
        <p>{message.bot_response}</p>
      </div>
    </div>
  );
};

export default ViewResponse;
