import React, { useState} from 'react';
import { FaComment, FaAddressBook, FaSpeakerDeck, FaToggleOff, FaFantasyFlightGames, FaRegWindowRestore } from 'react-icons/fa';
import ChatBox from './components/ChatBox';
import ErrorLogTable from './components/ErrorLogTable';
import AxiosInstance from '../../../axiosConfig';
import { FaRobot } from 'react-icons/fa6';

const AllToolsMenu = () => {
  const [showChatBox, setShowChatBox] = useState(false);
  const [showErrorLogs, setShowErrorLogs] = useState(false);
  const [errorLogs, setErrorLogs] = useState([]);

  const handleChatSimulatorClick = () => {
    setShowChatBox(true);
  };

  const handleErrorLogsClick = async () => {
    try {
      const response = await AxiosInstance.get('/get-error-log');
      if (response.data.status === 'success') {
        setErrorLogs(response.data.error_logs);
        setShowErrorLogs(true);
      } else {
        console.error('Failed to fetch error logs');
      }
    } catch (error) {
      console.error('Error fetching error logs:', error);
    }
  };

  if (showChatBox) {
    return <ChatBox />;
  }

  if (showErrorLogs) {
    return <ErrorLogTable errorLogs={errorLogs} />;
  }

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-14 lg:grid lg:grid-cols-12">
        <div className="col-span-8 lg:grid lg:grid-cols-3 gap-4">
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg">
            <FaAddressBook className="text-blue-500 text-2xl" />
            <p className="text-center mt-2">Customer Leads</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg">
            <FaSpeakerDeck className="text-green-500 text-2xl" />
            <p className="text-center mt-2">BroadCast Message</p>
          </div>
          <div
            className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg cursor-pointer"
            onClick={handleChatSimulatorClick}
          >
            <FaComment className="text-yellow-500 text-2xl" />
            <p className="text-center mt-2">Chat Simulator</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg">
            <FaToggleOff className="text-green-500 text-2xl" />
            <p className="text-center mt-2">OpenAI Setup</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg">
            <FaFantasyFlightGames className="text-green-500 text-2xl" />
            <p className="text-center mt-2">Analytics</p>
          </div>
          <div
            className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg cursor-pointer"
            onClick={handleErrorLogsClick}
          >
            <FaRegWindowRestore className="text-red-500 text-2xl" />
            <p className="text-center mt-2">Error Logs</p>
          </div>
          <div className="flex flex-col items-center justify-center bg-gray-100 p-4 rounded-lg">
            <FaRobot className="text-green-500 text-2xl" />
            <p className="text-center mt-2">Create Bot</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllToolsMenu;
