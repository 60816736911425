import React from 'react'
import Card from 'components/card'
import Switch from 'components/switch'

const GlobalConfigs = () => {
    return (
        <Card extra={"w-1/2 h-full p-3 mt-3"}>
            <div className="relative mb-3 flex items-center justify-between  pt-1 ml-3">
                <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
                   Global Configs
                </h4>
            </div>
            <div className="flex flex-col ml-3">
                {/* the custom checkbox desing added in src/index.js */}
                <div className="mt-3 flex items-center gap-3">
                    <Switch id="switch1" />
                    <label
                        for="checkbox1"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Allow multi-lingua translation
                    </label>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch2" />
                    <label
                        for="checkbox2"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Activate GenAI Capability
                    </label>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch3" />
                    <label
                        for="checkbox3"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Enforce Terms and Conditions Acceptance
                    </label>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch4" />
                    <label
                        for="checkbox4"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Pause Bot Responding
                    </label>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch5" />
                    <label
                        for="checkbox5"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Escalate to Human Agents
                    </label>
                </div>

                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch6" />
                    <label
                        for="checkbox6"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Block Spamming
                    </label>
                </div>
                <div className="mt-4 flex items-center gap-3">
                    <Switch id="switch7" />
                    <label
                        for="checkbox7"
                        className="text-lg font-medium text-navy-700 dark:text-white"
                    >
                        Extend/relate to other bots
                    </label>
                </div>
               
               
            </div>
        </Card>
    )
}

export default GlobalConfigs