import React, { useState, useEffect, useCallback } from "react";
import MiniCalendar from "components/calendar/MiniCalendar";
import MonthlyActivity from "views/admin/default/components/MonthlyActivity";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoRefresh } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import { columnsDataCheck } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import MessagingActivity from "views/admin/default/components/MessagingActivity";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import AxiosInstance from "../../../axiosConfig";
import { setItem, getItem } from "utils/localStorage";
import "utils/loadingSpinner.css"

const Dashboard = () => {
  const [botData, setBotData] = useState(null);
  const [error, setError] = useState(null);
  const [botId, setBotId] = useState("");
  const [botList, setBotList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDashboardStats = useCallback(async () => {
    const accessToken = getItem("access_token");
    if (!accessToken) {
      console.error("No access token found");
      setError("No access token found");
      return;
    }

    setIsLoading(true);
    try {
      const response = await AxiosInstance.get("/dashboard-stats");
      if (response.status !== 200) {
        throw new Error("Failed to fetch dashboard stats");
      }
      const stats = response.data;
      setBotData(stats);

      if (stats.bot_name && stats.switch_bots) {
        const switchBots = Object.entries(stats.switch_bots).map(([id, name]) => ({
          id: id,
          name: name.trim(),
        }));
        setBotList(switchBots);
        setBotId(stats.active_bot_id);
        setItem("bot_name", stats.bot_name);
      }
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
      setError("Failed to fetch dashboard stats.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const switchBot = useCallback(async (newBotId) => {
    try {
      const response = await AxiosInstance.get(`/switch-bot/${newBotId}`);
      if (response.status !== 200) {
        throw new Error("Failed to switch bot");
      }
      return true;
    } catch (error) {
      console.error("Error switching bot:", error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error
      } else {
        alert("Failed to switch bot. Please try again.");
      }
      return false;
    }
  }, []);

  const handleSwitchBot = useCallback(async (newBotId) => {
    if (newBotId === botData?.active_bot_id) {
      return;
    }
    setIsLoading(true);
    const success = await switchBot(newBotId);
    if (success) {
      await fetchDashboardStats();
    }
  }, [botData?.active_bot_id, switchBot, fetchDashboardStats]);

  useEffect(() => {
    fetchDashboardStats();
  }, [fetchDashboardStats]);

  useEffect(() => {
    if (botId) {
      handleSwitchBot(botId);
    }
  }, [botId, handleSwitchBot]);

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (isLoading) {
    return  (<div className="spinner-overlay">
    <div className="spinner"></div>
    <div className="loading-text">Loading Dashboard..</div>
  </div>);
  }

  if (!botData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* widgets */}
      <div className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          subtitle={botList.length === 1 ? botList[0].name : botList.find(bot => bot.id === botId)?.name || ""}
        >
          {botList.length > 1 && (
            <div className="flex items-center mt-2">
              <select
                value={botId}
                onChange={(e) => setBotId(e.target.value)}
                className="border border-gray-300 rounded-md p-0.5 text-xs mr-1 w-20 sm:w-28 focus:outline-none focus:border-blue-500"
              >
                <option value="" disabled>Select bot</option>
                {botList.map((bot) => (
                  <option key={bot.id} value={bot.id}>
                    {bot.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </Widget>

        <Widget
          icon={<IoRefresh className="h-6 w-6" />}
          title={"Messages Received"}
          subtitle={botData ? botData.tot_msgs : ""}
          link="/" 
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Response Accuracy"}
          subtitle={botData ? `${botData.bot_accuracy}/10` : ""}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-5" />}
          title={"Customers Engaged"} 
          subtitle={botData ? botData.tot_visitors : ""}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-8" />}
          title={"User Timezone"}
          subtitle={botData ? botData.user_timezone : ""}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-8" />}
          title={"Live Nodes"}
          subtitle={botData ? botData.tot_nodes : ""}
        />
      </div>

      {/* Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <MonthlyActivity botId={botData} />
        <MessagingActivity botId={botId}/>
        <PieChartCard />
      </div>

      {/* Tables & Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>
        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
