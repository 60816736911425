import React, { useState, useEffect } from "react";
import { MdArrowDropUp, MdArrowDropDown, MdOutlineCalendarToday } from "react-icons/md";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import AxiosInstance from "../../../../axiosConfig";

const MonthlyActivity = ({ botId }) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      toolbar: { show: false },
    },
    xaxis: {
      categories: [],
    },
  });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [isLoading, setIsLoading] = useState(false);
  const [percentageIncrease, setPercentageIncrease] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const year = new Date().getFullYear();
        const formattedDate = `${year}-${selectedMonth.toString().padStart(2, "0")}-01`;

        // Fetch data for the current and previous months
        const response = await AxiosInstance.post("/messaging-activity-trends", {
          duration_in: "months",
          stats_date_from: formattedDate,
          array_expected: "True"
        });

        const data = response.data;
        if (Array.isArray(data)) {
          const categories = data.map(item => item.month);
          const seriesData = data.map(item => item.texts_sent);

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: { categories },
          }));
          setChartData([{ name: "Texts Sent", data: seriesData }]);

          // Calculate percentage increase for the current month compared to the average of previous months
          const totalMonths = seriesData.length;
          if (totalMonths > 1) {
            const currentMonthValue = seriesData[totalMonths - 1];
            const previousMonthsAverage = seriesData.slice(0, totalMonths - 1).reduce((sum, value) => sum + value, 0) / (totalMonths - 1);

            if (previousMonthsAverage === 0) {
              setPercentageIncrease(currentMonthValue > 0 ? 100 : 0); // Handle division by zero
            } else {
              const percentage = ((currentMonthValue - previousMonthsAverage) / previousMonthsAverage) * 100;
              setPercentageIncrease(percentage.toFixed(1));
            }
          } else {
            setPercentageIncrease(0); // Only one month of data available
          }
        } else {
          console.error("Data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (botId) {
      fetchData();
    }
  }, [selectedMonth, botId]);

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value));
  };

  const formattedPercentageIncrease =
    percentageIncrease !== null && percentageIncrease !== undefined
      ? percentageIncrease
      : "0.0";

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <div className="relative">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="linear mt-1 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
          >
            {[...Array(12).keys()].map((month) => (
              <option key={month + 1} value={month + 1}>
                {new Date(0, month, 1).toLocaleDateString("en", { month: "long" })}
              </option>
            ))}
          </select>
          <MdOutlineCalendarToday className="absolute right-0 top-0 bottom-0 mt-auto mb-auto mr-2 text-gray-600 pointer-events-none" />
        </div>
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Monthly Activity
          </h4>
          <p className="text-sm font-medium leading-4 text-gray-600">
            Number of texts sent per month
          </p>
        </div>
        <div className="flex flex-row items-center justify-center">
          {percentageIncrease !== null && percentageIncrease !== 0 ? (
            percentageIncrease > 0 ? (
              <MdArrowDropUp className="font-medium text-green-500" />
            ) : (
              <MdArrowDropDown className="font-medium text-red-500" />
            )
          ) : null}
          <p className={`text-sm font-bold ${percentageIncrease > 0 ? 'text-green-500' : percentageIncrease < 0 ? 'text-red-500' : 'text-gray-500'}`}>
            {isLoading ? "Loading..." : `${formattedPercentageIncrease}% ${percentageIncrease > 0 ? 'increase' : percentageIncrease < 0 ? 'decrease' : 'no change'}`}
          </p>
        </div>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex flex-col">
          <div className="flex flex-col items-start"></div>
        </div>
        <div className="h-full w-full">
          <LineChart options={chartOptions} series={chartData} />
        </div>
      </div>
    </Card>
  );
};

export default MonthlyActivity;