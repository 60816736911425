import React, { useState, useEffect } from "react";
import { MdCheckCircle } from "react-icons/md";
import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import AxiosInstance from 'axiosConfig'; 

const ChannelStatsCard = () => {
  const [channelStats, setChannelStats] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChannelStats = async () => {
      try {
        const response = await AxiosInstance.get("/channel-stats");
        const data = response.data;
        const formattedData = Object.entries(data).map(([in_channel_name, messages_count]) => ({
          in_channel_name,
          messages_count,
        }));
        setChannelStats(formattedData);
      } catch (error) {
        console.error("Error fetching channel stats:", error);
        setError("Failed to fetch channel stats.");
      }
    };

    fetchChannelStats();
  }, []);

  return (
    <Card extra="pb-7 p-[20px]">
      {/* Task Header */}
      <div className="relative flex flex-row justify-between">
        <div className="flex items-center">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-100 dark:bg-white/5">
            <MdCheckCircle className="h-6 w-6 text-brand-500 dark:text-white" />
          </div>
          <h4 className="ml-4 text-xl font-bold text-navy-700 dark:text-white">
            Channel Stats
          </h4>
        </div>
        <CardMenu />
      </div>

      {/* Task Content */}
      <div className="h-full w-full">
        {error && (
          <div className="mt-2 text-base font-bold text-red-600">
            {error}
          </div>
        )}

        {!error && channelStats.length > 0 && (
          <div className="mt-5">
           
            {channelStats.map((channel, index) => (
              <div
                key={channel.in_channel_name}
                className={`grid grid-cols-2 py-2 ${index % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700' : ''} border-b border-gray-200`}
              >
                <div className="flex items-center gap-2 pl-2">
                  <Checkbox />
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    {channel.in_channel_name}
                  </p>
                </div>
                <div className="flex items-center justify-end pr-2">
                  <span className="text-base font-bold text-navy-700 dark:text-white">
                    {channel.messages_count}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default ChannelStatsCard;
